import { stat } from "fs";

const initialState = {
    cookieAceito: false,
    dotzAceito: false,
    dotzIndicado: '',
    tipoUsuario: 'nala',
    dadosIniciais: {
        nome: '',
        telefone: '',
        email: '',
        unidadeConsumidora: '',
        consumoInicial: '',
        userAcceptedTerms: true
    },
    dadosContrato: {
        numCliente: '',
        numInstalacao: '',
        conexao: 'Bifásico',
        classe: '',
        contaAnexo: ''
    }, 
    endereco: {
        cep: '',
        cidade: '',
        uf: '',
        bairro: '',
        logradouro: '',
        numero: '',
        complemento: '',
        canHire: '',
    },
    historicoConsumo: {
        meses: ['', '', '', '', '', '', '', '', '', '', '', ''],
        media: 0,
        calculoFinal: 0
    },
    dadosResponsavel: {
        nome: '',
        dataNasc: '',
        email: '',
        cpf: '',
        rg: '',
        rgOrgao: 'SSP',
        rgOrgaoUf: 'MG',
        estadoCivil: '',
        nacionalidade: 'Brasileira',
        documentoAnexo: '',
        cupom: "SOLATIO",
        economiaPercentual: 15,
        matricula: ''
    },
    enderecoResponsavel: {
        cep: '',
        cidade: '',
        uf: '',
        bairro: '',
        logradouro: '',
        numero: '',
        complemento: ''
    },
    dadosEmpresa: {
        razaoSocial: '',
        cnpj: '',
        tipoSociadade: '',
        email: '',
        tipoRegistro: '',
        nire: '',
        comarca: '',
        numComarca: ''
    }
}

export default function home(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_INICIAL':
            return {
                ...state,
                dadosIniciais: {
                    ...state.dadosIniciais,
                    nome: action.nome,
                    telefone: action.telefone,
                    email: action.email,
                    unidadeConsumidora: action.unidadeConsumidora,
                    consumoInicial: action.consumoInicial,
                    userAcceptedTerms: action.userAcceptedTerms
                }
            };
        case 'UPDATE_CONTRATO':
            return {
                ...state,
                dadosContrato: {
                    ...state.dadosContrato,
                    numCliente: action.numCliente,
                    numInstalacao: action.numInstalacao,
                    conexao: action.conexao,
                    classe: action.classe,
                    contaAnexo: action.contaAnexo
                }
            };
        case 'UPDATE_ENDERECO':
            return {
                ...state,
                endereco: {
                    ...state.endereco,
                    cep: action.cep,
                    cidade: action.cidade,
                    uf: action.uf,
                    bairro: action.bairro,
                    logradouro: action.logradouro,
                    numero: action.numero,
                    complemento: action.complemento,
                    canHire: action.canHire,
                },
            };
        case 'UPDATE_HISTORICO':
            return {
                ...state,
                historicoConsumo: {
                    ...state.historicoConsumo,
                    meses: action.meses,
                    media: action.media,
                    calculoFinal: action.calculoFinal
                }
            };
        case 'UPDATE_MATRICULA':
            return {
                ...state,
                dadosResponsavel: {
                    ...state.dadosResponsavel,
                    matricula: action.matricula
                }
            };
        case 'UPDATE_RESPONSAVEL_MIN':
            return {
                ...state,
                dadosResponsavel: {
                    ...state.dadosResponsavel,
                    nome: action.nome,
                    profissao: action.profissao,
                    estadoCivil: action.estadoCivil,
                    nacionalidade: action.nacionalidade,
                    cupom: action.cupom,
                    matricula: action.matricula
                }
            };
        case 'UPDATE_RESPONSAVEL':
            return {
                ...state,
                dadosResponsavel: {
                    ...state.dadosResponsavel,
                    nome: action.nome,
                    email: action.email,
                    dataNasc: action.dataNasc,
                    cpf: action.cpf,
                    rg: action.rg,
                    rgOrgao: action.rgOrgao,
                    rgOrgaoUf: action.rgOrgaoUf,
                    profissao: action.profissao,
                    estadoCivil: action.estadoCivil,
                    nacionalidade: action.nacionalidade,
                    documentoAnexo: action.documentoAnexo,
                    cupom: action.cupom,
                    economiaPercentual: action.economiaPercentual,
                    matricula: action.matricula
                }
            };
        case 'UPDATE_CUPOM':
            return {
                ...state,
                dadosResponsavel: {
                    ...state.dadosResponsavel,
                    cupom: action.cupom
                }
            };
        case 'UPDATE_ENDERECO_RESPONSAVEL':
            return {
                ...state,
                enderecoResponsavel: {
                    ...state.enderecoResponsavel,
                    cep: action.cep,
                    cidade: action.cidade,
                    uf: action.uf,
                    bairro: action.bairro,
                    logradouro: action.logradouro,
                    numero: action.numero,
                    complemento: action.complemento,
                },
            };
        case 'UPDATE_EMPRESA':
            return {
                ...state,
                dadosEmpresa: {
                    ...state.dadosEmpresa,
                    razaoSocial: action.razaoSocial,
                    cnpj: action.cnpj,
                    tipoSociadade: action.tipoSociadade,
                    email: action.email,
                    tipoRegistro: action.tipoRegistro,
                    nire: action.nire,
                    comarca: action.comarca,
                    numComarca: action.numComarca
                }
            };
        case 'UPDATE_COOKIE':
            return {
                ...state,
                cookieAceito: action.cookieAceito
            };
        case 'UPDATE_COOKIE_DOTZ':
            return {
                ...state,
                dotzAceito: action.dotzAceito
            };
        case 'UPDATE_USER_TYPE':
            return {
                ...state,
                tipoUsuario: action.userType,
                mrv:  action.userType === 'mrv',
                mrvCliente: action.userType === 'mrvCliente' || action.userType === 'mrvLuggo' ,
            };
        case 'UPDATE_DOTZ_INDICADO':
            return {
                ...state,
                dotzIndicado: action.dotzIndicado
            };
        case 'CLEAR_USER':
            return {
                ...state,
                tipoUsuario: 'nala',
                dadosIniciais: initialState.dadosIniciais,
                dadosContrato: initialState.dadosContrato,
                endereco: initialState.endereco,
                historicoConsumo: initialState.historicoConsumo,
                dadosResponsavel: initialState.dadosResponsavel,
                enderecoResponsavel: initialState.enderecoResponsavel,
                dadosEmpresa: initialState.dadosEmpresa
            };
         case 'CLEAR':
            state = initialState;
            return state;
        default:
            return state
    }
};
