import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React, { Suspense, lazy }  from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { store, persistor  } from './logic/stores/store';
import { PersistGate } from 'redux-persist/integration/react';

import './style/main.scss';
import { isHomolog } from './logic/helpers';

const Layout = lazy(() => import('./layout/layout'));
const Home = lazy(() => import('./views/home'));
const Simulador = lazy(() => import('./views/simulador'));
const Simulacao = lazy(() => import('./views/simulacao'));
const DadosInstalacao = lazy(() => import('./views/dadosInstalacao'));
const DadosResponsavel = lazy(() => import('./views/dadosResponsavel'));
const DadosEmpresa = lazy(() => import('./views/dadosEmpresa'));
const Contato = lazy(() => import('./views/contato'));
const Finalizacao = lazy(() => import('./views/finalizacao'));
const PoliticaPrivacidade = lazy(() => import('./views/politicaPrivacidade'));
const PoliticaCookies = lazy(() => import('./views/politicaCookies'));
const NaMidia = lazy(() => import('./views/naMidia'));
const EscolherDados = lazy(() => import('./views/escolherDados'));
const Usinas = lazy(() => import('./views/usinas'));
const ClienteMRV = lazy(() => import('./views/mrvCliente'));
const MRV = lazy(() => import('./views/mrv'));
const Sicoob = lazy(() => import('./views/sicoob'));
const Liasa = lazy(() => import('./views/liasa'));
const ART21 = lazy(() => import('./views/art21'));
const KDPenergia = lazy(() => import('./views/kdpEnergia'));
const MarioPenna = lazy(() => import('./views/mariopenna'));

const config = {
  isHomolog: isHomolog(),
  kwhMinPF: 150, // Pag 1 (Nala) => PF
  kwhMinPJ: 150, // Pag 1 (Nala) => PJ

  
  kwhMinPFMonofasico: 150, // Pag 2 (Nala) => PF => Monof.
  kwhMinPFBifasico: 150, // Pag 2 (Nala) => PF => Bif.
  kwhMinPFTrifasico: 150, // Pag 2 (Nala) => PF => Trif.

  kwhMinPJMonofasico: 150, // Pag 2 (Nala) => PJ => Monof.
  kwhMinPJBifasico: 150, // Pag 2 (Nala) => PJ => Bif.
  kwhMinPJTrifasico: 150, // Pag 2 (Nala) => PJ => Trif.


  kwhMinPFMRV: 80, // Pag 1 (Outros) => PF
  kwhMinPJMRV: 80, // Pag 1 (Outros) => PJ

  
  kwhMinPFMonofasicoMRV: 80, // Pag 2 (Outros) => PF => Monof.
  kwhMinPFBifasicoMRV: 80, // Pag 2 (Outros) => PF => Bif.
  kwhMinPFTrifasicoMRV: 80, // Pag 2 (Outros) => PF => Trif.

  kwhMinPJMonofasicoMRV: 80, // Pag 2 (Outros) => PJ => Monof.
  kwhMinPJBifasicoMRV: 80, // Pag 2 (Outros) => PJ => Bif.
  kwhMinPJTrifasicoMRV: 80, // Pag 2 (Outros) => PJ => Trif.


  kwhMultiplicador: .9,
  kwhSubtracaoMonofasico: 30,
  kwhSubtracaoBifasico: 50,
  kwhSubtracaoTrifasico: 100,

  landingPages: [ "/mrvcolab", "/clientemrv", "/sicoob-coopsef", "/kdpenergia"]
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
            <Switch>
              <Route path={"/"} exact render={props => <Layout Component={Home} menuOpen={false} config={config} {...props} />} />
              <Route path={"/naMidia/"} exact render={props => <Layout Component={NaMidia} carrossel={false} menuOpen={true} config={config} {...props} />} />
              <Route path={"/simulador/"} exact render={props => <Layout Component={Simulador} menuOpen={false} config={config} {...props} />} />
              <Route path={"/simulador/simulacao"} render={props => <Layout Component={Simulacao} menuOpen={true} config={config} {...props} />} />
              <Route path={"/simulador/dadosInstalacao"} render={props => <Layout Component={DadosInstalacao} menuOpen={true}config={config} {...props} />} />
              <Route path={"/simulador/dadosCliente"} render={props => <Layout Component={DadosResponsavel} menuOpen={true} config={config} {...props} />} />
              <Route path={"/simulador/dadosResponsavel"} render={props => <Layout Component={DadosResponsavel} menuOpen={true} config={config} {...props} />} />
              <Route path={"/simulador/dadosEmpresa"} render={props => <Layout Component={DadosEmpresa} menuOpen={true} config={config} {...props} />} />
              <Route path={"/simulador/finalizacao"} render={props => <Layout Component={Finalizacao} menuOpen={true} config={config} {...props} />} />
              <Route path={"/simulador/EscolherDados"} render={props => <Layout Component={EscolherDados} menuOpen={true} config={config} {...props} />} />
              <Route path={"/usinas"} render={props => <Layout Component={Usinas} menuOpen={false} config={config} {...props} />} />
              <Route path={"/politicaprivacidade"} render={props => <Layout Component={PoliticaPrivacidade} menuOpen={true} config={config} {...props} />} />
              <Route path={"/politicacookies"} render={props => <Layout Component={PoliticaCookies} menuOpen={true} config={config} {...props} />} />
              <Route path={"/contato"} render={props => <Layout Component={Contato} menuOpen={true} config={config} {...props} />} />
              <Route path={"/ClienteMRV"} render={props => <Layout Component={ClienteMRV} menuOpen={true} config={config} {...props} />} />
              <Route path={"/MRVColab"} render={props => <Layout Component={MRV} menuOpen={true} config={config} {...props} />} />
              <Route path={"/sicoob-coopsef"} render={props => <Layout Component={Sicoob} menuOpen={true} config={config} {...props} />} />
              <Route path={"/kdpEnergia"} render={props => <Layout Component={KDPenergia} menuOpen={true} config={config} {...props} />} />
              <Route path={"/mario-penna"} render={props => <Layout Component={MarioPenna} menuOpen={true} config={config} {...props} />} />
              <Route path={"/liasa"} render={props => <Layout Component={Liasa} menuOpen={true} config={config} {...props} />} />
              <Route path={"/art21"} render={props => <Layout Component={ART21} menuOpen={true} config={config} {...props} />} />
              <Route exact path="/areadocliente" render={() => (window.location = "https://area-cliente.solatioenergialivre.com.br/#/dashboard")} />
              <Route path={"/*"} exact render={props => <Layout Component={Home} menuOpen={false} config={config} {...props} />} />
            </Switch>
          </Suspense>
        </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root-react")
);
